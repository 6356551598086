<template>
  <div class="send-code">
    <div class="code-btn" v-if="time == 0" @click="sendSmsCode">
      <span>{{ codeText }}</span>
    </div>
    <div class="send-text" v-else>
      <van-count-down :time="time" @finish="finishSend">
        <template #default="timeData">
          <span class="block">{{ timeData.seconds }}S后重新获取</span>
        </template>
      </van-count-down>
    </div>
  </div>
</template>
<script>
export default {
  name: "SendCode",
  props: {
    time: {
      type: Number,
      default: 0,
    },
    // 倒计时时间
  },
  data() {
    return {
      codeText: "获取验证码",
    };
  },
  methods: {
    /**
     * 发送验证码
     */
    sendSmsCode() {
      this.$emit("sendcode", 60 * 1000);
    },
    /**
     * 倒计时结束
     */
    finishSend() {
      this.codeText = "重新获取";
      console.log(this.codeText);
      this.$emit("updateTime", 0);
    },
  },
};
</script>
